import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import { IoDocument, IoDocumentOutline } from "react-icons/io5";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function Document() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Документы</h1>
                    </div>
                </section>
            
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Общие документы</h2>
                        <div onClick={() => window.open("/files/1_ustav.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Устав образовательной организации</p>
                        </div>
                        <div onClick={() => window.open("/files/2_license.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Свидетельство о государственной аккредитации – выписка из реестра лицензий на осуществление образовательной деятельности, выданные Департаментом образования и науки города Москвы</p>
                        </div>
                        <div onClick={() => window.open("/files/3_rules_for_students.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Правила внутреннего распорядка обучающихся</p>
                        </div>
                        <div onClick={() => window.open("/files/4_labor_regulations.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Правила внутреннего трудового распорядка</p>
                        </div>
                        <div onClick={() => window.open("/files/5_position_of_selfexamination.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Положение о порядке проведения самообследования</p>
                        </div>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Локальные нормативные акты образовательной организации по основным вопросам организации и осуществления образовательной деятельности</h2>
                        <div onClick={() => window.open("/files/6_rules_for_admission_of_additional_education.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Правила приёма на обучение по программам дополнительного профессионального образования</p>
                        </div>
                        <div onClick={() => window.open("/files/7_rules_of_admission_of_the_educational_program.pdf", "_blank")}  className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Правила приёма на обучение по дополнительным общеобразовательным общеразвивающим программам</p>
                        </div>
                        <div onClick={() => window.open("/files/8_regulations_on_mode_of_classes.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Положение о режиме занятий обучающихся</p>
                        </div>
                        <div onClick={() => window.open("/files/9_regulations_on_certification.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Формы, периодичность и порядок текущего контроля успеваемости и промежуточной аттестации обучающихся</p>
                        </div>
                        <div onClick={() => window.open("/files/10_order_of_transfer.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Порядок и основания перевода, отчисления и восстановления обучающихся</p>
                        </div>
                        <div onClick={() => window.open("/files/11_order_of_registration_of_relations.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Порядок оформления возникновения, приостановления и прекращения отношений между образовательной организацией и обучающимися</p>
                        </div>
                        <div onClick={() => window.open("/files/12_provision_of_paid_services.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Положение об оказании платных образовательных услуг</p>
                        </div>
                        <div onClick={() => window.open("/files/13_application_of_the_form_of_agreements.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Порядок оказания платных образовательных услуг (образец договора с юридическим лицом и образец договора с физическим лицом)</p>
                        </div>
                        {/* 23/05/2024 ПРОСЬБА УБРАТЬ ДО МОМЕНТА ПОЯВЛЕНИЯ В ИНСТИТУТЕ ПЛАТНЫХ ПРОГРАММ
                        <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Приказ о стоимости обучения</p>
                        </div> */}
                    </div>
                </section>

                {/*<Container className='content'>
                    <h1 className='h1_text'>Документы</h1>

                    <h2 className='h2_text_first h2_text'>Общие документы</h2>
                    <div>
                        <div onClick={() => window.open("/files/1_ustav.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Устав образовательной организации</span>
                        </div>
                        <div onClick={() => window.open("/files/2_license.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Свидетельство о государственной аккредитации – выписка из реестра лицензий на осуществление образовательной деятельности, выданные Департаментом образования и науки города Москвы</span>
                        </div>
                        <div onClick={() => window.open("/files/3_rules_for_students.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Правила внутреннего распорядка обучающихся</span>
                        </div>
                        <div onClick={() => window.open("/files/4_labor_regulations.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Правила внутреннего трудового распорядка</span>
                        </div>
                        <div onClick={() => window.open("/files/5_position_of_selfexamination.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Положение о порядке проведения самообследования</span>
                        </div>
                    </div>

                    <h2 className='h2_text'>Локальные нормативные акты образовательной организации по основным вопросам организации и осуществления образовательной деятельности</h2>
                    <div onClick={() => window.open("/files/6_rules_for_admission_of_additional_education.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Правила приёма на обучение по программам дополнительного профессионального образования</span>
                    </div>
                    <div onClick={() => window.open("/files/7_rules_of_admission_of_the_educational_program.pdf", "_blank")}  className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Правила приёма на обучение по дополнительным общеобразовательным общеразвивающим программам</span>
                    </div>
                    <div onClick={() => window.open("/files/8_regulations_on_mode_of_classes.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Положение о режиме занятий обучающихся</span>
                    </div>
                    <div onClick={() => window.open("/files/9_regulations_on_certification.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Формы, периодичность и порядок текущего контроля успеваемости и промежуточной аттестации обучающихся</span>
                    </div>
                    <div onClick={() => window.open("/files/10_order_of_transfer.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Порядок и основания перевода, отчисления и восстановления обучающихся</span>
                    </div>
                    <div onClick={() => window.open("/files/11_order_of_registration_of_relations.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Порядок оформления возникновения, приостановления и прекращения отношений между образовательной организацией и обучающимися</span>
                    </div>
                    <div onClick={() => window.open("/files/12_provision_of_paid_services.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Положение об оказании платных образовательных услуг</span>
                    </div>
                    <div onClick={() => window.open("/files/13_application_of_the_form_of_agreements.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Порядок оказания платных образовательных услуг (образец договора с юридическим лицом и образец договора с физическим лицом)</span>
                    </div>
                     23/05/2024 ПРОСЬБА УБРАТЬ ДО МОМЕНТА ПОЯВЛЕНИЯ В ИНСТИТУТЕ ПЛАТНЫХ ПРОГРАММ
                     <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Приказ о стоимости обучения</span>
                    </div> 

                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default Document;