import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import { IoDocument, IoDocumentOutline } from "react-icons/io5";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';


function PaidService() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Платные образовательные услуги</h1>
                    </div>
                </section>
                
                <section className='section_light'>
                    <div className='main_container'>
                        <h6 className='h6_text'>АНО «ИДП им. Д. И. Менделеева» оказывает услуги на коммерческой основе.</h6>
                        <div onClick={() => window.open("/files/12_provision_of_paid_services.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Положение о порядке оказания платных образовательных услуг</p>
                        </div>
                        <div onClick={() => window.open("/files/13_application_of_the_form_of_agreements.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Приказ об утверждении формы договора об оказании платных образовательных услуг</p>
                        </div>                    
                        {/*23/05/2024 ПРОСЬБА УБРАТЬ ДО МОМЕНТА ПОЯВЛЕНИЯ В ИНСТИТУТЕ ПЛАТНЫХ ПРОГРАММ    
                        <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Приказ об утверждении стоимости обучения</p>
                        </div>*/}
                    </div>
                </section>
                {/*<Container className='content'>
                    <h1 className='h1_text'>Платные образовательные услуги</h1>
                    <h6 className='h6_text'>АНО «ИДП им. Д. И. Менделеева» оказывает услуги на коммерческой основе.</h6>
                    <Col>
                        <div onClick={() => window.open("/files/12_provision_of_paid_services.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Положение о порядке оказания платных образовательных услуг</span>
                        </div>
                        <div onClick={() => window.open("/files/13_application_of_the_form_of_agreements.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Приказ об утверждении формы договора об оказании платных образовательных услуг</span>
                        </div>
                     23/05/2024 ПРОСЬБА УБРАТЬ ДО МОМЕНТА ПОЯВЛЕНИЯ В ИНСТИТУТЕ ПЛАТНЫХ ПРОГРАММ                       
                        <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                            <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <span className='icon_text'>Приказ об утверждении стоимости обучения</span>
                        </div> 
                    </Col>
                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default PaidService;