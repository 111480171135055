import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function AccessibleEnvironment() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

        
    return(
        <div>
            <div style={{ fontSize: `${fontScale}%` }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
            <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Доступная среда</h1>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container accessibleenvironment'>
                        <p>В здании образовательной организации обеспечен доступ для инвалидов и лиц с ограниченными возможностями здоровья.</p>
                        <Image src={showImages ? "images/accessible_environment.png" : "images/nullimgaccessibleenvironment.png"}/>
                    </div>
                </section>

                
                
                {/*<Container className='content'>
                <h1 className='h1_text'>Доступная среда</h1>
                <Row className='accessibleenvironment'>
                        <Col md={12} lg={6}>
                            <p>В здании образовательной организации обеспечен доступ для инвалидов и лиц с ограниченными возможностями здоровья.</p>
                        </Col>
                        <Col md={12} lg={6}>
                            <Image src={showImages ? "images/accessible_environment.png" : "images/nullimgaccessibleenvironment.png"}/>
                        </Col>
                    </Row>
                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default AccessibleEnvironment;