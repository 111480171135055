import React, { useEffect, useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, Outlet, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import { GoMoveToTop } from "react-icons/go";
import Footer from './Footer';
import { IoDocumentOutline } from "react-icons/io5";



const HomePage: React.FC = () => {
  const contextOutlet: AccessibilityState = useOutletContext() 
  const { showAccessibilityOptions, fontScale, contrast, showImages
    , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
   } = contextOutlet;

  const images = [
    { src: 'images/homePageGallery/gallery_1.jpg', interval: 3000 },
    { src: 'images/homePageGallery/gallery_2.jpg', interval: 3000 },
    { src: 'images/homePageGallery/gallery_3.jpg', interval: 3000 },
    { src: 'images/homePageGallery/gallery_4.jpg', interval: 3000 },
    { src: 'images/homePageGallery/gallery_5.jpg', interval: 3000 },
    { src: 'images/homePageGallery/gallery_6.jpg', interval: 3000 },
  ];

  const [currentIndex, setCurrentIndex] = React.useState(0);
    const totalImages = images.length;

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1));
    };

    const handleIndicatorClick = (index: number) => {
        setCurrentIndex(index);
    };

    React.useEffect(() => {
        const interval = images[currentIndex].interval || 3000; // По умолчанию 3 секунд
        const timer = setInterval(handleNext, interval);

        return () => {
            clearInterval(timer);
        };
    }, [currentIndex]);


  return (
    <div>
      <div style={{ fontSize: fontScale + '%'}} className={`${getContrastClassName(contrast)}`}>
        <section className='lead'>
          <div className='main_container'>
            <h1>Институт<br/>демографической<br/>политики<br/>имени Д. И. Менделеева</h1>
            {showImages && <Image src={"images/lead.png"} className="mx-1" />}
          </div>
        </section>

        <section className='target'>
          <div className={(fontScale==100 && getContrastClassName(contrast)=='' && showImages) ? 'main_container' : 'main_container_target_accesibility'}>
            <div className='target_about'>
              <h1>ОБ ИНСТИТУТЕ</h1>
                <p>Учреждён 18 апреля 2022 года решением глав регионов Центрального федерального округа в форме автономной некоммерческой организации.
                <br /><br />Основной фокус работы института направлен на повышение рождаемости через формирование в обществе статуса многодетной семьи как социальной нормы.</p>
            </div> 
            {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) 
            ? 
            <div className="target-circle">
            <div className="circle-container">
              <div className="circle circle-large">
                <p>Уточнение демографической политики через анализ, экспертизу, подбор и внедрение в практику инструментов достижения суммарного коэффициента рождаемости более 2.15.</p>
              </div>
              <div className="circle circle-small">
                <p>Цель<br />института</p>
            </div>
          </div></div>
            :
            <div className='target_about'>
              <h1>ЦЕЛЬ ИНСТИТУТА</h1>
                <p>Уточнение демографической политики через анализ, экспертизу, подбор и внедрение в практику инструментов достижения суммарного коэффициента рождаемости более 2,15.</p>
            </div> 
            }
            
          </div>
        </section>

        <section className='educational-activity'>
          <div className='main_container'>
          <>
              <h1>ОБРАЗОВАТЕЛЬНАЯ<br />ДЕЯТЕЛЬНОСТЬ</h1>
              <div className='main_row'>
                <Col className='white-rectangle left-rectangle'>
                  <div className="main_row home-contacts-row">
                    <h2>Лицензия на осуществление образовательной деятельности</h2>
                    {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/education.svg" width={50} height={50} />}
                  </div>
                  <p>Образовательная деятельность осуществляется
                  на основании Лицензии от 28 декабря 2023 г. №Л035-01298-77/01010690 на осуществление образовательной деятельности, выданной Департаментом образования и науки города Москвы.</p>
                  <p onClick={() => window.open("/files/2_license.pdf", "_blank")} className='save_doc'>Скачать документ</p>
                </Col>
                <Col className='white-rectangle right-rectangle'>
                  <div className="main_row home-contacts-row">
                    <h2>Реализуемые программы</h2>
                    {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/doc.svg" width={50} height={50} />}
                  </div>
                  <h3>Программа повышения квалификации «Дневник самоконтроля качества жизни»</h3>
                  <p>Учебная программа включает в себя обучение работе внутри программы ЭВМ «Дневник самоконтроля качества жизни», которая создаёт условия для диалога между гражданином и центром компетенций (это представители власти, врачи, кураторы).</p>
                </Col>
              </div>  
            </>
          </div>
        </section>

        {showImages && 
        <section className='gallery'>
          <div className='main_container'>
            <h1>ГАЛЕРЕЯ</h1>
            
              <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                {images.map((_, index) => (
                    <button
                        type="button"
                        key={index}
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index === currentIndex ? 'active' : ''}
                        aria-current={index === currentIndex ? 'true' : 'false'}
                        aria-label={`Slide ${index + 1}`}
                        onClick={() => handleIndicatorClick(index)}
                    />
                ))}
            </div>
                  <div className="carousel-inner">
                      {images.map((image, index) => (
                          <div
                              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                              key={index}
                              data-bs-interval={image.interval || 5000} // Устанавливаем интервал как атрибут
                          >
                              <img src={image.src} className="d-block w-100" alt={`Slide ${index + 1}`} />
                          </div>
                      ))}
                  </div>
                  <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleInterval"
                      onClick={handlePrev}
                  >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Предыдущая</span>
                  </button>
                  <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleInterval"
                      onClick={handleNext}
                  >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Следующая</span>
                  </button>
              </div>
            </div>
        </section>
        }

        <Footer/>
      </div>
    </div>);
}

export default HomePage;