import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import TeacherModal from './TeacherModal';

interface ITeacher {
    name: string
    imgSrc: string
    desc: string
}



const Guide = () => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const [modalShow, setModalShow] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalImage, setModalImage] = useState('');
    const [modalText, setModalText] = useState('');

    const trustees = [
        {"name" : "Щёголев Игорь Олегович", "imgSrc": "images/guide/shegolev.png","desc": "Полномочный представитель Президента Российской Федерации в Центральном федеральном округе, Председатель Попечительского совета Института демографической политики имени Д. И. Менделеева"},
        {"name" : "Шишкарёв Сергей Николаевич", "imgSrc": "images/guide/shishkarev.png","desc": "Председатель Совета директоров Группы компаний «Дело», заместитель Председателя Попечительского совета, доктор юридических наук"},
        {"name" : "Иваницкий Валерий Людвигович", "imgSrc": "images/guide/ivanickiy.png","desc": "Научный руководитель Института демографической политики имени Д. И. Менделеева, заместитель Председателя Попечительского совета, доктор филологических наук, профессор МГУ имени М. В. Ломоносова"},
        
        {"name" : "Анисимов Никита Юрьевич", "imgSrc": "images/guide/anisimov.png","desc": "Ректор Национального исследовательского университета «Высшая школа экономики»"},
        {"name" : "Артамонов Игорь Георгиевич", "imgSrc": "images/guide/artamonov.png","desc": "Губернатор Липецкой области"},
        {"name" : "Бахтизин Альберт Рауфович", "imgSrc": "images/guide/bahtizin.png","desc": "Директор Центрального экономико-математического института Российской академии наук (ЦЭМИ РАН), член-корреспондент РАН"},
        
        {"name" : "Кипкеев Борис Геннадьевич", "imgSrc": "images/guide/kipkeev.png","desc": "Главный советник Председателя Совета директоров Группы компаний «Дело»"},
        {"name" : "Кондрашов Андрей Олегович", "imgSrc": "images/guide/kondrashov.png","desc": "Генеральный директор федерального государственного унитарного предприятия «Информационное телеграфное агентство России (ИТАР-ТАСС)»"},
        {"name" : "Немкин Антон Игоревич", "imgSrc": "images/guide/nemkin.png","desc": "Депутат Государственной Думы Федерального Собрания Российской Федерации"},
        
        {"name" : "Новиков Сергей Геннадьевич", "imgSrc": "images/guide/novikov.png","desc": "Начальник Управления Президента Российской Федерации по общественным проектам"},
        {"name" : "Певцов Дмитрий Анатольевич", "imgSrc": "images/guide/pevcov.png","desc": "Народный артист Российской Федерации, первый заместитель Председателя Комитета Государственной Думы по культуре"},
        {"name" : "Прокофьев Станислав Евгеньевич", "imgSrc": "images/guide/prokofiev.png","desc": "Ректор Финансового университета при Правительстве Российской Федерации, доктор экономических наук, профессор"},
        
        {"name" : "Святенко Инна Юрьевна", "imgSrc": "images/guide/svytenko.png","desc": "Заместитель Председателя Совета Федерации Федерального Собрания Российской Федерации, доктор технических наук, профессор"},
        {"name" : "Старовойт Роман Владимирович", "imgSrc": "images/guide/starovoit.png","desc": "Министр транспорта Российской Федерации"},
        {"name" : "Фёдоров Валерий Валерьевич", "imgSrc": "images/guide/fedorov_vv.png","desc": "Генеральный директор Всероссийского центра изучения общественного мнения (ВЦИОМ)"},
        
        {"name" : "Федоров Максим Валериевич", "imgSrc": "images/guide/fedorov_mv.png","desc": "И. о. директора Института проблем передачи информации имени А. А. Харкевича Российской академии наук (ИППИ РАН), член-корреспондент РАН"},
        {"name" : "Черныш Михаил Фёдорович", "imgSrc": "images/guide/chernysh.png","desc": "Директор Федерального научно-исследовательского социологического центра Российской академии наук (ФНИСЦ РАН), член-корреспондент РАН"},
        {"name" : "Якунин Вадим Сергеевич", "imgSrc": "images/guide/yakunin.png","desc": "Председатель Совета директоров АО «Протек»"}
    ]

    const staff = [
        {"name" : "Ефимов Иван Павлович", "imgSrc": "images/guide/efimov.png","desc": "Исполнительный директор Института демографической политики имени Д. И. Менделеева, руководитель учебного центра Института демографической политики имени Д. И. Менделеева"},
        {"name" : "Елфимов Владимир Геннадьевич", "imgSrc": "images/guide/elfimov.png","desc": "Эксперт проектного офиса «Общественное мнение» Института демографической политики имени Д. И. Менделеева, заместитель шеф-редактора национального демографического агентства ДЕМ.ИНФОРМ, кандидат технических наук"},
        {"name" : "Ковалева Дарья Михайловна", "imgSrc": "images/guide/kovaleva.png","desc": "Референт Института демографической политики имени Д. И. Менделеева"},
        
        {"name" : "Кормихин Александр Андреевич", "imgSrc": "images/guide/kormihin.png","desc": "Главный бухгалтер Института демографической политики имени Д. И. Менделеева"},
        {"name" : "Косоуров Егор Евгеньевич", "imgSrc": "images/guide/kosourov.png","desc": "Руководитель проектного офиса «Человеческий капитал» Института демографической политики имени Д. И. Менделеева, шеф-редактор национального демографического агентства ДЕМ.ИНФОРМ"},
        {"name" : "Кривохижин Олег Юрьевич", "imgSrc": "images/guide/krivozhihin.png","desc": "Заместитель исполнительного директора Института демографической политики имени Д. И. Менделеева, заведующий лабораторией ответственного бизнеса НИУ ВШЭ"}
        ]

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>РУКОВОДСТВО</h1>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Попечительский совет</h2>
                        <p>Попечительский совет – коллегиальный орган, проводящий общественную экспертизу проектов и инициатив Института демографической политики имени Д. И. Менделеева.</p>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container'>
                        <div className="teachers">
                            {trustees.map((trustee: ITeacher) => (
                                <div className="teacher" key={trustee.name}>
                                    <img src={showImages ? trustee.imgSrc : 'images/nullteacher.png'} alt="" style={{marginTop: '0px'}}/>
                                    <div className='teacher_text'>
                                        <h2>{trustee.name}</h2>
                                        <p>{trustee.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>            

                <section className='section_light'>
                    <div className='main_container'>
                        <h2 className='h2_only'>Сотрудники</h2>
                    </div>
                </section>

                <section className='section_blue'>
                    {/*<div className='main_container' style={{paddingBottom: '0'}}>*/}
                    <div className='main_container'>
                        {/*<div className="teachers" style={{marginBottom: '0'}}>*/}
                        <div className="teachers">
                            {staff.map((employee: ITeacher) => (
                                <div className="teacher" key={employee.name}>
                                    <img src={showImages ? employee.imgSrc : 'images/nullteacher.png'} alt="" />
                                    <div className='teacher_text'>
                                        <h2>{employee.name}</h2>
                                        <p>{employee.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {/*<section className='section_blue'>
                    <div className='main_container' style={{paddingTop: '0'}}>
                        <div className="teachers">
                            <div className="teacher ceo" key={"Скоробогатов Андрей Владимирович"}>
                                <img src={showImages ? "images/guide/skorobogatov.png" : 'images/nullteacher.png'} alt="" />
                                <div className='teacher_text'>
                                    <h2>Скоробогатов Андрей Владимирович</h2>
                                    <p>Руководитель направления социально-корпоративного развития Института демографической политики имени Д. И. Менделеева</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}


                <section className='section_light'>
                    <div className='main_container'>
                        <h2 className='h2_only'>Педагогические работники</h2>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container'>
                        <div className="teachers" style={{marginBottom: '60px'}}>
                            <div className="teacher ceo" key={"Михайлова Елена Александровна"}>
                                <img src={showImages ? "images/guide/mihailova.png" : 'images/nullteacher.png'} alt="" />
                                <div className='teacher_text'>
                                    <h2>Михайлова Елена Александровна</h2>
                                    <p>Кандидат социологических наук, советник генерального директора Всероссийского центра изучения общественного мнения (ВЦИОМ)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                


                {/*<Container className='content'>
                    <h1 className='h1_text'>Руководство. Педагогический (научно-педагогический) состав</h1>
                    <h2 className='h2_text'>Руководитель образовательной организации</h2>
                    <Row className='guide'>
                        <Col md={12} lg={4}>
                            <Image src={showImages ? "images/EfimovIP.png" : "images/nullimgguide.png"} className="mx-1" alt="EfimovIP" />
                        </Col>
                        <Col md={12} lg={8}>
                            <h3 className='h3_text'>Ефимов Иван Павлович</h3>
                            <p>Исполнительный директор Института демографической политики имени Д. И. Менделеева, руководитель учебного центра института</p>
                        </Col>
                    </Row>
                    
                    <h2 className='h2_text'>Состав педагогических работников</h2>
                    <Row>
                        <Col className='teacher'>
                            <Image src={showImages ? "images/MihailovaEA.png" : "images/nullimgguide.png"} alt="MihailovaEA" />
                            <div>
                                <h3 className='h3_text'>Михайлова Елена Александровна</h3>
                                <p>Преподаватель учебного центра института</p>
                                <Button variant="primary" 
                                onClick={() => 
                                    {setModalShow(true); 
                                    setModalName('Михайлова Елена Александровна');
                                    setModalImage('images/MihailovaEA.png');
                                    setModalText('Кандидат социологических наук, советник генерального директора Всероссийкого центра изучения общественного мнения')}
                                    }>
                                    Подробнее
                                </Button>
                                <TeacherModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    name={modalName}
                                    image={modalImage}
                                    text={modalText}
                                />
                            </div>
                        </Col>
                        <Col className='teacher'>
                            <Image src={showImages ? "images/SkorobogatovAV.png" : "images/nullimgguide.png"} alt="SkorobogatovAV" />
                            <div>
                                <h3 className='h3_text'>Скоробогатов Андрей Владимирович</h3>
                                <p>Преподаватель учебного центра института</p>
                                <Button onClick={() => 
                                    {setModalShow(true); 
                                    setModalName('Скоробогатов Андрей Владимирович');
                                    setModalImage('images/SkorobogatovAV.png');
                                    setModalText('Руководитель направления социально-корпоративного развития Института демографической политики имени Д. И. Менделеева')}
                                    }>
                                    Подробнее
                                </Button>
                                <TeacherModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    name={modalName}
                                    image={modalImage}
                                    text={modalText}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <Footer/>
            </div>
        </div>
    )
}

export default Guide;