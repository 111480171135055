import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Modal, Form, Button, Row, Col, Image } from 'react-bootstrap';
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import { useOutletContext } from 'react-router-dom';

const Footer = () => {
  const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)}`}>
      <section className='home-contacts-section' style={{marginBottom: '0px'}}>
          <div className='main_container' style={{paddingBottom: '25px'}}>
            <h1>КОНТАКТЫ</h1>
            <div className='main_row'>
              <Col className='white-rectangle left-rectangle'>
                <div className="main_row home-contacts-row">
                  <h2>Адрес</h2>
                  {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/map.svg" width={50} height={50} />}
                </div>
                <p>г. Москва, ул. Никольская, д. 10, офис 601</p>
                <p><a href="https://yandex.ru/maps/-/CDSXQB6D" className='map'>Перейти на карту</a></p>
              </Col>
              <Col className='white-rectangle right-rectangle'>
                <div className="main_row home-contacts-row">
                  <h2>Контакты</h2>
                  {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/mail.svg" width={50} height={50} />}
                </div>
                <p><a href="tel:+74952216991">+7 (495) 221-69-91</a></p>
                <p><a href="mail:info@indemp.ru">info@indemp.ru</a></p>
              </Col>
            </div>
          </div>
        </section>
      
      <div className='main_container' style={{paddingTop: '0px', paddingBottom: '0px'}}>
        <div className='white-rectangle'>
            <p><a>© {currentYear} Институт демографической политики имени Д. И. Менделеева</a></p>
            <p><a href="https://minobrnauki.gov.ru/">Министерство науки и высшего образования Российской Федерации</a></p>
            <p><a href="https://edu.gov.ru/">Министерство просвещения Российской Федерации</a></p>
        </div>
      </div>
    </footer>
    );
};

export default Footer;