import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function FinancialActivity() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;
     
    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Финансово-хозяйственная деятельность</h1>
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <p>АНО «ИДП им. Д. И. Менделеева» не является получателем субсидий на выполнение государственного (муниципального) задания, целевых субсидий, а также бюджетных инвестиций. В связи с этим все финансовые показатели в плане финансово-хозяйственной деятельности и отчёта о финансово-хозяйственной деятельности, отражаются по деятельности от оказания платных образовательных услуг.</p>   
                    </div>
                </section>
                {/*<Container className='content'>
                    <h1 className='h1_text'>Финансово-хозяйственная деятельность</h1>
                    <Row>
                        <p>АНО «ИДП им. Д. И. Менделеева» не является получателем субсидий на выполнение государственного (муниципального) задания, целевых субсидий, а также бюджетных инвестиций. В связи с этим все финансовые показатели в плане финансово-хозяйственной деятельности и отчёта о финансово-хозяйственной деятельности, отражаются по деятельности от оказания платных образовательных услуг.</p>
                    </Row>
                </Container>*/}
                <Footer />
            </div>
        </div>
    )
}

export default FinancialActivity;